import * as React from 'react';
import Cursor from './cursor';
import { usePresenceWithCursors } from './use-cursors';

export default function OtherCursors({
    showChat = false,
}: {
    showChat: boolean;
}) {
    const otherUserIds = usePresenceWithCursors((state) =>
        Array.from(state.otherUsers.keys())
    );
    const within = usePresenceWithCursors((state) => state.within);

    const style = {
        position: (within === 'window'
            ? 'fixed'
            : 'absolute') as React.CSSProperties['position'],
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'clip',
        pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
    };

    const fillColors = [
        'hsl(235deg 79% 70%)',
        'hsl(83deg 95% 55%)',
        'hsl(25deg 100% 55%)',
        'hsl(216deg 100% 73%)',
        'hsl(50deg 100% 50%)',
    ];

    function randomFillColor(index: number) {
        const color = fillColors[fillColors.length % (index + 1)];
        return color;
    }

    return (
        <div style={style}>
            {otherUserIds.map((id, index) => {
                return (
                    <Cursor
                        key={id}
                        userId={id}
                        fill={randomFillColor(index)}
                        showChat={showChat}
                    />
                );
            })}
        </div>
    );
}
